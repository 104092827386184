.job {
  outline: 0;
}

.job-title {

  position: relative;
  padding-left: 1.3em;

  a {
    outline: 0;
  }
}

.job-title-icon {
  position: absolute;
  left: 0;
  top: 3px;
  display: inline-block;

  .job-title.opened & {
    transform: rotate(90deg) translateY(10px);
  }
}

.job-description {
  display: none;
  margin-left: 30px;
  margin-top: 10px;
}

.job-description.opened, .cke_editable .job-description {
  display: block;
}