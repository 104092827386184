

body.path-news, body.node-type-news {

  .page-banner:after {
    display: none !important;
  }

  .page-body-title {
    display: none !important;
  }

}
