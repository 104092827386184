* {
	margin: 0;
	padding: 0;
}

*, *:before, *:after {
	box-sizing: border-box;
}

html, body {
	height: 100% !important;
}

body {

	line-height: 1.4;
	padding-top: 70px;

	&.toolbar {
		padding-top: 0;
	}
}

img {
	max-width: 100%;
	height: auto;
}

a, a:hover, a:active, a:visited {
	color: #000;
	text-decoration: none;
}

p {
	margin-bottom: 20px;
}

ul {
	list-style-type: none;
}

ul.standard {
	list-style-type: disc;
	list-style-position: outside;
	margin-left: 1.3em;

	& li {
		margin-top: 0.2em;
	}
}

input {
	-webkit-border-radius: 0px;
	-webkit-appearance: none; 
}

input[type=text], input[type=password], textarea {
	border:1px solid #888;
	outline: 0;
	padding: 4px;
}

div[rel=text], [rel=email] {
	line-height: 1.7;
	margin: 12px 0;
}


input[type=checkbox] {
	border:1px solid #888;
	height: 14px;
	//margin-bottom: 4px;
	margin-right: 10px;
	width: 14px;
	-webkit-appearance: checkbox;
}

div[rel=checkbox] {
	line-height: 1.7;
	margin-bottom: 12px;
}

input[type=submit] {
	padding: 4px 8px;
}

select {
	border: 1px solid #888;
	background-color: #FFF;
}

label {
	margin-bottom: 4px;
	font-weight: normal;
}

th {
	border-bottom: 1px solid #CCC;
}

tr.even, tr.odd {
	background-color: #FFF;
	border-bottom: 1px solid #CCC;
	padding: 0.3em 0.6em;
}

tr.odd {
	background-color: #F5F5F5;
}

td,th {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

table.draggable {

	width: 100% !important;

	.handle {
		width: 22px !important;
		height: 22px !important;
		margin-left: 0.8em !important;
	}
}

hr {
	border:0;
	height: 1px;
	font-size: 0px;
	display: block;
	background: #000;
	padding: 1em 0;
	background-origin: padding-box;
	clear: both;
}

.form-required {
	color: #000;
}
