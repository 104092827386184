
.product-filters {

	position: relative;
	z-index: 10;

	@include media($mediumToLarge) {
		float: left;
		margin-right: 10px;
	}
}

.product-filters li {

	display: block;

	@include media($mediumToLarge) {
		display: inline-block;
	}
}

.product-filters li a {

	display: inline-block;
	width: 100%;
	text-align: center;
	padding: 10px;

	@include media($mediumToLarge) {
		padding: 0 7px 0 7px;
		line-height: 49px;
		height: 49px;
		text-align: left;
		border-bottom: 0 none;
	}

	@include media($small) {
		background-color: #bfeaf3;
		border-bottom: 1px solid #7fd4e7;
	}
}

.product-filters li.active a {
	background-color: $highlight-color;
	color: #FFF;
	position: relative;

	@include media($small) {
		color: #333;
	}	

	&:before {
		@include media($mediumToLarge) {
			color: #FFF;
			font-size: 16px;
			line-height: 5px;
			bottom: -2px;
			left: 50%;
			margin-left: -8px;
			display: block;
			position: absolute;
		  font-family: "lamp83-icons" !important;
		  content: "i";
		}
	}
}

.product-filters-toggle {
	text-align: center;
	text-transform: uppercase;
	display: block;
	padding: 5px 0 15px 0;
	background-color: #bfeaf3;
	border-bottom: 1px solid #7fd4e7;
	position: relative;
	cursor: pointer;

	&:before {
		font-family: "lamp83-icons" !important;
		content: "a";
	  text-transform: none;
		font-size: 16px;
		margin-left: -8px;
		display: block;
		position: absolute;
		left: 50%;
		bottom: 1px;
	}
}