.contact-person-block {
  position: fixed;
  bottom: 0px;
  right: 0;
  z-index: 960;
  width: 300px;
  background: #FFF;
  clear: both;
  overflow: hidden;
  box-shadow: 0 0 1px rgba(0,0,0,.3);
  @include transition(0.1s all linear);

  @include transform(translateY(150px));

  @include media($smallToMedium) {
    position: relative;
    width: 100%;
    border: 0px solid #000;
    box-shadow: none;
    padding-top: 20px;
    @include transform(translateY(0px));
  }
}

.contact-person-block--open {

  @include transform(translateY(0px));

  .contact-person-block-button { 
    @include transform(rotate(0deg));
  }
}

.contact-person-block-title {
  background: rgba(0,170,205,1);
  color: #FFF;
  padding: 0 0 0 15px;
  height: 40px;
  line-height: 40px;
  display: block;
  cursor: pointer;
  position: relative;
  @extend %bold-font;

  @include media($smallToMedium) {
    background: #FFF;
    color: #000;
    padding: 0 0 5px 0;
    border-bottom: 1px solid #000;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.contact-person-block-image {
  float: left;
  width: 100px;
  height: auto;
  margin: 15px 0 15px 15px;

  @include media($smallToMedium) {
    margin-left: 0px;
    margin-top: 0;
  }
}

.contact-person-block-details {
  margin-left: 130px;
  margin-top: 15px;

  @include media($smallToMedium) {
    margin-top: 0;
    margin-left: 115px;
  }
}

.contact-person-block-button { 
  position: absolute;
  top: 5px;
  right: 20px;
  color: #FFF;
  outline: 0 none;
  @include transform(rotate(180deg));
  @include transform-origin(center 17px);
  display: block;

  @include media($smallToMedium) {
    display: none;
  }

  &:link, &:hover, &:active, &:visited {
    color: #EEE;
    outline: 0 none;
  }
}