.product-list {
	overflow: hidden;
	@include transition(max-height 0.3s ease-in-out 0s, opacity 0.2s ease, transform 0.5s linear);
	opacity: 0;
	max-height: 0;
	height: 0;

	.product-groups-item-anchor:target ~ & { 
		opacity: 1;
		max-height: 1000px;
		height: auto;
		@include transition(max-height 0.5s ease-in-out 0s, opacity 0.4s ease 0.4s, transform 0.5s linear);

		@include media($small) {

			max-height: 1700px;
		}
	}
}

.product-list-item {

  @include media($small) {
    @include span-columns(1 of 1);
  	@include omega(1n);
  }

	@include media($medium) {
		@include span-columns(3 of 6);
	  @include omega(2n);
	}	

	@include media($large) {
		@include span-columns(3 of 12);
		@include omega(4n);
	}

	min-height: 110px;
	margin-top: 15px;
	padding-top: 4px;
	border-top: 1px solid #000;
	position: relative;
}


.product-list-item-title {
	float : left;
	text-transform: uppercase;
	font-size: 100%;
	max-width: 57%;
	word-wrap: break-word;
	padding-left: 2px;
}

.product-list-item img {
	float: right;
	max-width: 40%;

  @include media($small) {
    max-width: 104px;
  }
}

.product-list-item-icons {
	bottom: 0;
	left: -3px;
	position: absolute;
	max-width: 59%;
	clear: both;
}

.product-list-item-icons .product-icon {
	float: left;
	margin: 3px 0 0 3px;
}


.product-list  {
	
	display: block;
	background: #EEE;
	overflow: hidden;
	width: 100%;
	position: relative;

	/**
	 * Positioning magic to move the product list out of the grid.
	 */
	@include media($large){

		$gridItemGutterRatio : 1  + flex-gutter(12) / flex-grid(3, 12);
		width: 300% * $gridItemGutterRatio + 100%;

		.product-groups-item:nth-child(4n + 1) & {

			margin-left: -100% * $gridItemGutterRatio * 0;
		}

		.product-groups-item:nth-child(4n + 2) & {

			margin-left: -100% * $gridItemGutterRatio * 1;
		}

		.product-groups-item:nth-child(4n + 3) & {

			margin-left: -100% * $gridItemGutterRatio * 2;
		}

		.product-groups-item:nth-child(4n + 4) & {

			margin-left: -100% * $gridItemGutterRatio * 3;
		}
	}

	@include media($medium){

		$gridItemGutterRatio : 1  + flex-gutter(6) / flex-grid(3, 6);
		width: 100% * $gridItemGutterRatio + 100%;

		.product-groups-item:nth-child(2n + 1) & {

			margin-left: -100% * $gridItemGutterRatio * 0;
		}

		.product-groups-item:nth-child(2n + 2) & {

			margin-left: -100% * $gridItemGutterRatio * 1;
		}
	}



	@include media($small){

		$gridItemGutterRatio : 1  + flex-gutter(12) / flex-grid(6, 12);
		width: 100% * $gridItemGutterRatio + 100%;

		.product-groups-item:nth-child(2n + 1) & {

			margin-left: -100% * $gridItemGutterRatio * 0;
		}

		.product-groups-item:nth-child(2n + 2) & {

			margin-left: -100% * $gridItemGutterRatio * 1;
		}
	}

}