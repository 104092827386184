@font-face{
	font-weight: normal;
	font-family: "Frutiger Next LT W06 Light";
	src: url("../fonts/1366590/35f2fcd5-0bd6-4974-8b4b-a0667bc609b9.eot?#iefix");
	src: url("../fonts/1366590/35f2fcd5-0bd6-4974-8b4b-a0667bc609b9.eot?#iefix") format("eot"),url("../fonts/1366590/a7b40a14-dbca-49ff-8668-a2808421455b.woff") format("woff"),url("../fonts/1366590/5439c10b-467e-4feb-b866-7b1b588bdc08.ttf") format("truetype"),url("../fonts/1366590/a1a93a97-03d2-4309-8393-3e4c9a452ff6.svg#a1a93a97-03d2-4309-8393-3e4c9a452ff6") format("svg");
}

@font-face{
	font-weight: normal;
	font-family: "Frutiger Next LT W06 Medium";
	src: url("../fonts/1366594/8c11db31-604a-4fff-90ea-5ccd389b7260.eot?#iefix");
	src: url("../fonts/1366594/8c11db31-604a-4fff-90ea-5ccd389b7260.eot?#iefix") format("eot"),url("../fonts/1366594/226dc050-0441-41e6-8d1b-11b9747812a2.woff") format("woff"),url("../fonts/1366594/8d86c89e-f099-49b3-b4cd-97907ba7124e.ttf") format("truetype"),url("../fonts/1366594/8524e4cf-1990-4ac9-a0de-c793c3fd5972.svg#8524e4cf-1990-4ac9-a0de-c793c3fd5972") format("svg");
}

%regular-font {
	font-family: "Frutiger Next LT W06 Light", Arial, sans-serif;
}

%bold-font {
	font-family: "Frutiger Next LT W06 Medium", Arial, sans-serif;
	font-weight: normal;
}

body {
	font-size: 93.75%;
	@extend %regular-font;
}

h1,h2,h3,h4 {
	@extend %regular-font;
	font-weight: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

b, strong {
	@extend %bold-font;	
	font-weight: normal;
}
