.node-product .ds-left {

	@include media($small) {
		@include clearfix();
	}

	@include media($medium) {
		@include span-columns(6 of 12);
	}

	@include media($large) {
		@include span-columns(6 of 12);
	}
}

.node-product .ds-middle {

	@include media($small) {
		padding-top: 15px;
	}

	@include media($medium) {
		@include span-columns(6 of 12);
		@include omega();
	}

	@include media($large) {
		@include span-columns(3 of 12);
	}
}

.node-product .ds-right {

	@include media($smallToMedium) {
		margin-top: 15px;
	}

	@include media($medium) {
		@include span-columns(6 of 12);
	}

	@include media($large) {
		@include span-columns(3 of 12);
		@include omega();
	}
}

.node-product .ds-right > * {

	padding-top: 14px;
	border-top: 1px solid #000;

	&:first-child {
		@include media($mediumToLarge) {
			border-top: 0 none;
			padding-top: 0;
		}
	}
}

.node-product .ds-bottom {
	clear: both;
}

.node-product .field-prod-icons-specific .product-icon {
	background-size: 48px 48px;
	width: 48px;
	height: 48px;
	margin-right: 2px;
	margin-bottom: 2px;
	float: left;
}

.field-prod-icons-specific {
	@include clearfix();
	padding-bottom: 15px;
	border-bottom: 1px solid #000;
}

.field-prod-icons-specific-labels {
	border-bottom: 1px solid #000;
	padding: 5px 0 15px 0;
}

.field-prod-description {
	clear: left;
	padding-top: 15px;
}



.ds-bottom h3 {
	font-size: 150%;
	text-transform: uppercase;
	border-bottom: 1px solid #000;
	padding: 35px 0 0 0;
	margin-bottom: 15px;
}

.field-prod-datasheet,
.field-prod-technical-drawings,
.field-prod-light-dist-curve {
	clear: left;
}


.field-prod-download {
	display: none;
	background-size: auto 70px;
	background-position: left top;
	background-repeat: no-repeat;
	background-size: 48px auto;
	height: 65px;
	padding: 6px 0 0 62px;

	&:first-child {

		display: block;
	}
}

.field-prod-download--pdf {
	background-image: url(../img/icons/download-icon-pdf.svg);
	height: 85px;
	padding: 12px 0 0 62px;
}

.field-prod-download--zip {
	background-image: url(../img/icons/download-icon-zip.svg);
	height: 85px;
	padding: 12px 0 0 62px;
}

.field-prod-download--tz {
	background-image: url(../img/icons/download-icon-technische-zeichnungen.svg);
}

.field-prod-download--lk {
	background-image: url(../img/icons/download-icon-leuchtkurven.svg);
}

.field-prod-download-title {
	display: block;
	line-height: 1.4;
	text-transform: uppercase;
}

.field-prod-download-subtitle {
	display: block;
	color: #999;
	font-size: 90%;
}

.field-prod-icons-general {

	@include media($small) {
		@include span-columns(1 of 1);
		@include omega();
	}

	@include media($medium) {
		@include span-columns(1 of 1);
		@include omega();
	}

	@include media($large) {
		@include span-columns(6 of 12);
	}

	@include clearfix();

	margin-top: 5px;
	border: 1px solid #000;
	border-width: 1px 0 1px 0;
	padding: 3px 0;
	font-size: 0px;

}

.field-prod-icons-general .product-icon{
	height: 28px;
	width: 14%;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
	margin-right: 6%;

	&:last-child {
		margin-right: 0;
	}
}


.field-prod-thumbnails {

	@include media($small) {
		@include span-columns(1 of 1);
		@include omega();
	}

	@include media($medium) {
		@include span-columns(1 of 1);
		@include omega();
	}

	@include media($large) {
		@include span-columns(6 of 12);
		@include omega();
	}


	margin-top: 5px;

	ul > li {		

		@include span-columns(6 of 12);
		
		@include omega(2n);
		margin-bottom: 3%;
	}

	img {
		display: block;
	}

}



.field-prod-variants {

	width: 100%;
	padding-top: 1px;

	table {

		width: 100%;

		@include media($small) {
			display: none;
		}

		@include media($mediumToLarge) {
			display: table;
		}

		thead {
			background-color: #000;
			color: #FFF;
		}

		th {
			padding: 2px;
			border-bottom: 0 none;
			font-weight: normal;
		}

		tbody tr {
			background-color: #FFF;
			border-bottom: 1px solid #AAA;
		}

		td {
			padding: 5px 2px;

			&.field_prod_variants_eulumdat {
				font-size: 21px;
				padding: 1px 2px 0px 2px;
			}
		}
	}

	table.stacktable {

		@include media($small) {
			display: table;
		}

		@include media($mediumToLarge) {
			display: none;
		}

		tbody tr {
			border-bottom: 0 none;
		}

		.st-head-row {
			color: #000;
			border: 4px solid #FFF;
			border-width: 32px 0 4px 0;
			padding: 7px 3px;
			@extend %bold-font;
		}

		.st-head-row-main {
			display: none;
		}

		td {
			padding: 1px 3px;
		}
	}
}

.field_prod_variants_eulumdat {
	text-align: center;
}
