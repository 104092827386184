
.product-back-link {
  margin-top: 15px;
  display: none;
  position: relative;
  z-index: 3;

  &:before {
    font-size: 12px;
    position: relative;
    top: 1px;
  }

  .js & {
    display: block;

    @include media($small) {
      display: none;
    } 

    @include media($medium) {
      display: none;
    } 

    @include media($large) {
      @include span-columns(6 of 12);
    } 
  }
 }