/**
 * Temporary location for styles.
 * Move these styles to separate files later.
 */

// Bugfix, @see https://www.drupal.org/node/1677374
.contextual-links-region:hover a.contextual-links-trigger {
  display: block;
 }

#toolbar * {
	box-sizing: content-box;
}

#toolbar-menu  {
	visibility: hidden;
}
