/**
 * Small
 */
.grid-s-33 {
  @include media($small) {
    @include span-columns(4 of 12);
  }
}

.grid-s-50 {
  @include media($small) {
    @include span-columns(6 of 12);
  }
}

.grid-s-100 {
  @include media($small) {
    @include span-columns(12 of 12);
  }
}

/**
 * Medium
 */
.grid-m-33 {
  @include media($medium) {
    @include span-columns(4 of 12);
  }
}

.grid-m-50 {
  @include media($medium) {
    @include span-columns(6 of 12);
  }
}

.grid-m-100 {
  @include media($medium) {
    @include span-columns(12 of 12);
  }
}

/**
 * Large
 */
.grid-l-33 {
  @include media($large) {
    @include span-columns(4 of 12);
  }
}

.grid-l-50 {
  @include media($large) {
    @include span-columns(6 of 12);
  }
}

.grid-l-100 {
  @include media($large) {
    @include span-columns(12 of 12);
  }
}

/**
 * General
 */
.grid-omega {
  @include omega();
}
