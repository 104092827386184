.menu-business-item {

	display: block;
	margin-bottom: 25px;
	background-repeat: no-repeat;
	background-size: 100% auto;

  @include media($small) {
		@include span-columns(6 of 12);
		@include omega(2n);
		margin-bottom: 10px;
  }

	@include media($medium) {
		@include span-columns(3 of 12);
		@include omega(4n);
	}	

	@include media($large) {
		@include span-columns(3 of 12);
		@include omega(4n);
	}
}

.menu-business-item-link {
	height: 0;
	width: 100%;
	padding-bottom: 100%;
	display: block;
	opacity: 1;
	outline: 0 !important;
	@include transition(opacity 0.4s ease-in-out);
	background-size: 100% auto;
	background-color: #FFF;

	&:hover {
		opacity: 1;
	}
}

.menu-business-item-title {
	margin-top: 5px;
	text-transform: uppercase;
}

$menu-business-items: tech corporate glossary downloads team;

@each $menu-business-item in $menu-business-items {

  .menu-business-item--#{$menu-business-item}  {
  	background-image: url(../img/business/dist/business-#{$menu-business-item}.jpg?13);
	}

  .menu-business-item--#{$menu-business-item} a {
  	background-image: url(../img/icons/business-icon-#{$menu-business-item}.svg);
  	opacity: 0;

  	&:hover {
  		opacity: 0.5;
  	}
	}
}