.references-list {
	margin-top: 20px;
}

.references-list-item {
	margin-bottom: 25px;
    position: relative;
    height: 0;
    padding-bottom: 30%;
    overflow: hidden;

    @include media($small) {
      @include span-columns(12 of 12);
      @include omega(1n);
      margin-bottom: 10px;
      padding-bottom: 75%;
    }

    @include media($medium) {
      @include span-columns(6 of 12);
      @include omega(2n);
    }

    @include media($large) {
      @include span-columns(6 of 12);
      @include omega(2n);
    }
}

.references-list-item-title {
    position: relative;
    z-index: 2;
    margin-bottom: 10px;
	text-transform: uppercase;
    font-size: 180%;
    color: #FFF;
    background-color: rgba($highlight-color, 0.9);
    padding: 6px;
    width: auto;
    display: inline-block;
    line-height: 1;

    @include media($small) {
      margin-bottom: 6px;
    }

    a {
      color: #FFF;
    }
}

.references-list-item-link a {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  opacity: 0;
  width: 100%;
  height: 100%;
  display: block;
}

.references-list-item-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  @include transition(opacity 0.2s linear);

  img {
    width: 100%;
  }

  .references-list-item:hover & {
    opacity: .2;
  }
}

.references-list-item-text {
  margin: 10px 8px 8px 8px;
  opacity: 0;
  @include transition(opacity 0.2s linear);


  @include media($small) {
    display: none;
  }

  .references-list-item:hover & {
    opacity: 1;
  }
}

.references-list-item-floor-space,
.references-list-item-business,
.references-list-item-year,
.references-list-item-luminaires,
.references-list-item-location {
  margin-left: 8px;
  opacity: 0;
  @include transition(opacity 0.2s linear);

  & > * {
    display: inline-block;
  }

  & > *:first-child {
    min-width: 60px;
    vertical-align: top;
  }

  .references-list-item:hover & {
    opacity: 1;
  }
}