.team-list {

  margin-top: 50px;

  @include media($small) {
    margin-top: 10px;
  }
}

.team-list-item {
  background-color: #f4f4f4;
  height: 0px;
  padding-bottom: 21.29%;
  position: relative;
  overflow: hidden;
  margin-bottom: 39px;

  @include media($small) {
    @include span-columns(4 of 12);
    padding-bottom: 38.29%;
    margin-bottom: 10px;
    @include omega(3n);
    /**
    &:nth-child(3n), &:nth-child(7n), &:nth-child(10n), &:nth-child(13n) {
      @include omega();
    }
    **/
  } 

  @include media($medium) {
    @include span-columns(2 of 10);
    @include omega(5n);
  } 

  @include media($large) {
    @include span-columns(2 of 10);
    @include omega(5n);
  } 

  img {
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    width: 100%;
  }

  h3 {
    position: absolute;
    z-index: 2;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 5px 14px;
    display: block;
    font-size: 170%;
    color: rgba(255,255,255,1);
    background-color: rgba(0, 170, 205, 0.6);
    opacity: 0;
    padding-top: 400px;
    line-height: 1.3;
    @include transform(translateY(0%));
    @include transition(all 0.2s $ease-out-quad);

    @include media($medium) {
      font-size: 150%;
    }

    @include media($small) {
      font-size: 120%;
    }
  }
}


.team-list-item:hover {

  h3 {
    opacity: 1;
  }

}

.team-list-item-placeholder {
  /**
  @include media($small) {
    display: none;
  }  
  **/
}