
.tooltipster-lamp83 {
	border-radius: 5px;
	background: #FFFFFF;
	color: #FFFFFF;
	@include transform(translateZ(0));
}

.tooltipster-lamp83 .tooltipster-content {
	@extend %regular-font;
	font-size: 14px;
	line-height: 16px;
	padding: 5px 10px;
	background-color: #000;
}

.tooltipster-lamp83 .tooltipster-arrow > span {
	border-top-color: #000 !important;
}

