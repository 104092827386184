.luminaires {

}

.luminaires-item {

	margin-bottom: 20px;

  @include media($small) {
		@include span-columns(6 of 12);
  	@include omega(2n);
 		margin-bottom: 10px;
  }

	@include media($medium) {
		@include span-columns(4 of 12);
	  @include omega(3n);
	}	

	@include media($large) {
		@include span-columns(3 of 12);
		@include omega(4n);
	}
}

.luminaires-item-title {
	text-transform: uppercase;
	margin: 5px 0;
	font-size: 120%;
	@extend %regular-font;
}

.luminaires-item-title a {
	text-decoration: none;
	color: #000;
}

.luminaires-item-image {
	padding-bottom: 100%;
	width: 100%;
	height: 0px;
	display: block;
	background-size: 100%;
	background-repeat: no-repeat;
	font-size: 0px;
}
