
.stacktable {
	width: 100%;
	display: none;

	@include media ($small) {
		display: block;
	}

	td {

		@include media ($small) {
			border-bottom: 1px solid #EEE;
		}
	}
}

.st-head-row.st-head-row-main {
	font-size: 1.5em;
	padding-top: 0;
}

.st-key {
	text-align: left;
	padding-right: 1%;
}

.st-val {

}

