.product-search-box {
	padding: 10px 0;
	position: relative;

	@include media($small) {
		margin-right: 15px;
		margin-left: 15px;
		float: none;
	}

	> div {
		overflow: hidden;

		@include media($small) {
			display: inline-block;
			width: 86%;
		}
	}
}

.product-search-box label {
	display: none;
}

.product-search-box [name=text] {
	border: 1px solid #D3D3D3;
	border-right: none;
	color: #666;
	line-height: 1;
	height: 29px;
	outline: 0;
	padding: 0 5px;
	float: right;
	width: 100%;
	max-width: 370px;
	@include transition(width 0.2s linear);

	@include media($small) {
		width: 100% !important;
		max-width: none;
	}

	/**
	&.expanded, .path-produkte &, .path-products & {
		width: 335px;
	}
	**/
}

.product-search-box-submit {
	background-color: #D3D3D3;
	border: 1px solid #D3D3D3;
	line-height: 29px;
	height: 29px;
	width: 47px;
	color: #FFF;
	font-size: 19px;
	outline: 0;
	float: right;

	&:active {
		background-color: #999;
	}

	&:before {
		position: relative;
		bottom: -2px;
	}

	@include media($small) {
		width: 14%;
	}
}
