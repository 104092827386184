.node-news {

}

.field-news-category-wrapper {
  text-align: center;
  display: block;
  position: relative;
  z-index: 2;

  &:before {
    z-index: 1;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid #000;
  }
}

.field-news-category {
  display: inline-block;
  background: #FFF;
  position: relative;
  z-index: 2;
  padding: 0 0.5em;
  font-size: 120%;
  text-transform: uppercase;
}

.node-news h1 {
  font-size: 180%;
  @extend %bold-font;
  color: $brand-red-color;
  text-align: center;
  margin: 0.5em 0;
}

.node-news .field-body {
  font-size: 18px;
}

.node-news-readmore {
  text-align: center;
  display: block;
  position: relative;
  z-index: 2;
  margin: 3em 0;

  &:before {
    z-index: 1;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid $highlight-color;
  }

  span {
    color: $highlight-color;
    display: inline-block;
    background: #FFF;
    position: relative;
    z-index: 2;
    padding: 0 0.5em;
    font-size: 120%;
    text-transform: uppercase;
  }
}