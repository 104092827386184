.intro-body-text {
  display: block;
  line-height: 1.4;
  max-width: 900px;
  width: 100%;
  text-align: justify;
  font-size: 25px;
  margin: 0 auto;
  padding: 80px;

  @include media($small) {
    text-align: left;
    padding: 40px;
  }
}

.intro-body-text-title {
  color: #e41513;
  @extend %bold-font;
  font-size: 150%;
  text-transform: uppercase;
}

.intro-slideshow {
  background: #000;
}



.intro-icon-header {
  max-width: 900px;
  width: 100%;
  background-size: 100% auto;
  background-repeat:no-repeat;
  height: 100px;
  margin: 80px auto 40px auto;
  background-position: center center;

  @include media($small) {
    background-size: auto 80%;
    margin: 30px auto 30px auto;
    background-clip: content-box;
    padding: 0 15px;
  }
}

.intro-separator-image{
  width: 100%;
  height: 150px;
  padding-bottom: 0;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;

  @include media($small) {
    height: 100px;
    background-attachment: inherit;
  }

  h3 {
    max-width: 900px;
    width: 100%;
    margin: 0 auto;
    font-size: 350%;
    @extend %bold-font;
    color: #FFF;
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    @include media($small) {
      padding-left: 40px;
    }

    span {
      color: #e41513;
    }
  }

}

.field-front-body p {
  max-width: 900px;
  width: 100%;
  margin: 0px auto 200px auto;
  font-size: 18px;
  line-height: 1.5;
  text-align: justify;

  @include media($small) {
    text-align: left;
    padding: 0px 15px 0px 15px;
    margin-bottom: 20px;
  }
}

.intro-ticker {
  max-width: 900px;
  margin: 0 auto;

  .marquee {
    height: 1.4em;
    margin: 120px auto 20px;

    &:before, &:after {
      content: none;
    }
  }

  .marquee-text {
    color: $highlight-color;
  }
}

body.path-node-21095 {
  footer {
    .marquee-text {
      display: none;
    }
  }
}


.field-front-footer {
  max-width: 900px;
  width: 100%;
  margin: 100px auto 100px auto;
  text-align: center;
  font-size: 190%;

  @include media($small) {
    padding: 0 15px;
  }
}

.views_slideshow_cycle_main {
  max-width: 100%;

  .views-row {
    position: relative;
  }

  img {
    display: block;
  }

  .field-slfr-slides-image ~ * {
    margin-left: 0px;

    @include media($small) {
      margin-left: 0px;
    }
  }

  .field-slfr-slides-title {
    color: #e41513;
    font-size: 450%;
    @extend %bold-font;
    margin-top: -300px;
    text-transform: uppercase;
    display: inline-block;
    font-size: 160%;
  }

  .field-slfr-slides-text {
    text-transform: uppercase;
    color: #FFF;
    font-size: 180%;
    display: block;
    max-width: 650px;

    @include media($small) {
      font-size: 110%;
    }
  }

  .views-field-field-slfr-slides-link {
    position: absolute;
    bottom: 80px;
    left: 80px;
    margin-right: 80px;

    p {
      margin-bottom: 0;
    }

    @include media($small) {
      left: 15px;
      margin-right: 15px;
      bottom: 40px;
    }

    @include media($medium) {
      left: 40px;
    }
  }

  .field-slfr-slides-linktext {
    display: inline-block;
    @extend %bold-font;
    font-size: 140%;
    background-color: #e41513;
    color: #FFF;
    text-transform: uppercase;
    padding: 0.2em 0.4em;
    margin-top: 15px;

    @include media($small) {
      font-size: 70%;
    }
  }

}

.widget_pager_bottom {

  position: absolute;
  z-index: 900;
  text-align: center;
  margin-top: -20px;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  max-width: 100%;

  > * {
    max-width: 100%;
  }

  .views_slideshow_pager_field_item {
    display: inline-block;
    margin: 0 5px;
    background: #FFF;
    width: 13px;
    height: 13px;
    font-size: 0px;
    border-radius: 50%;
    transition: 0.2s all linear;
    cursor: pointer;

    &.active {
      background: #e41513;
    }

    > * {
      display: none;
    }
  }

  img {
    display: block;
  }
}
