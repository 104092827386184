.glossary-table tbody {
 border: 0 none;
}

.glossary-table td {
  vertical-align: top;
  padding: 8px 12px 8px 0;
  line-height: 1.1;
}

$glossary-icons: enec ce schutzklasse-1 schutzklasse-2 schutzklasse-3;

@each $glossary-icon in $glossary-icons {
  .glossary-icon--#{$glossary-icon} {
  	background-image: url(../img/icons/glossary-icon-#{$glossary-icon}.svg);
		background-color: $highlight-color;
	}
}


[class*=glossary-icon] {
	background-color: #FFF;
}