
.product-icon {
	display: inline-block;
	width: 29px;
	height: 29px;
	background-size: 29px 29px;
}

$product-icons-specific: 'notlicht' 'schwenkbar' 'reflektor' 'reflektionsgrad' 'facettenreflektor' 'dimmbar' 'blendschutz';

@each $product-icon in $product-icons-specific {
  .product-icon--#{$product-icon} {
  	background-image: url(../img/icons/product-icon-#{$product-icon}.svg);
		background-color: $highlight-color;
	}
}

$product-icons-general: ce enec pct ukr ip20 ip30 ip40 ip44 ip50 ip54;

@each $product-icon in $product-icons-general {
  .product-icon--#{$product-icon} {
  	background-image: url(../img/icons/product-icon-#{$product-icon}.svg);
		background-color: #FFF;
	}
}

.product-icon--glossary {
	background-size: 55px 55px;
	width: 55px;
	height: 55px;
}