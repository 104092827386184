.text-strong {
  @extend %bold-font; 
}

.text-regular {
  @extend %regular-font; 
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
  @include hyphens(auto);
}

.text-hyphen {
  @include hyphens(auto); 
}

.text-underline {
  text-decoration: underline;
}

.text-size-s-large {
  @include media($small) {
    font-size: 150% !important;
  }
}

.no-transform {
  @include transform(none);
}