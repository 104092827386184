.downloads-icon {
	display: block;
	padding-bottom: 14px;
}

.downloads-icon:before {
  font-size: 30px; 
  top: 10px;
	left: -6px;
  position: relative;
}

.downloads-item {

}

.downloads-list li {
  /**
  @extend %icon-l83;
  position: relative;
  border-bottom: 1px solid #EEE;
  padding: 10px 25px 10px 30px;

  &:before {
    content: 'l';
    font-size: 30px;
    position: absolute;
    top: 10px;
    left: 0;
  }**/
  display: block;
  padding: 20px 55px 20px 5px;
  border-bottom: 1px solid #EEE;
  position: relative;

  &:hover {
    background-color: #F5F5F5;
  }
}

.downloads-list p {
  margin-bottom: 0;
}

.downloads-list a {
  display: block;
}

.downloads-list h3 {
  @extend %bold-font;
}

[class*=downloads-icon] {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  height: 60px;
  width: 62px;
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  right: 3px;
  margin-top: -30px;
}

.downloads-icon-pdf {
  background-image: url(../img/icons/download-icon-pdf.svg);
}

.downloads-icon-zip {
  background-image: url(../img/icons/download-icon-zip.svg);
}

.downloads-icon-catalog {
  background-image: url(../img/icons/download-icon-catalog.png);
}
