.node-page--image .group-left{

	@include span-columns(5 of 8);

	@include media($small) {

		@include span-columns(1 of 1);
		@include omega();
	}

}

.node-page--image .group-right{

	@include span-columns(3 of 8);
	@include omega();

	@include media($small) {

		@include span-columns(1 of 1);
		@include omega();
	}

}

.node-page--image .field-body h4 {
	margin-top: 35px;
	margin-bottom: 15px;
	@extend %bold-font;	
}

.field-page-attachments {
	margin-top: 25px;
}

.field-page-attachments-link{

	display: block;
	height: 30px;

	span {
		display: inline-block;
		position: relative;
		top: -10px;
	}

	&:before {
		font-size: 30px;
	}
}