.page-wrapper {
	min-height: 100%;
	position: relative;
	padding-bottom: 300px;
}

.page-header {
	min-height: 70px;
	background-color: rgba(255,255,255,.93);
	position: relative;
	z-index: 998;
	top: 0px;
	position: fixed;
	width: 100%;

	.compact-menu--visible & {
		background-color: #000;
	}

	body.admin-menu &, body.toolbar & {
		position: relative;
	}
}

.page-header-inner {

	@include media($small) {
		@include pad(0 15px 0 15px);
	}

	@include media($medium) {
		@include pad(0 30px 0 30px);
	}

	@include media($large) {
		@include pad(0 80px 0 80px);
	}

	position: relative;
}

.page-header-logo {
	background: url(../img/page-header-logo.svg) no-repeat;
	//width: 213px;
	//height: 30px;
	width: 190px;
	height: 39px;
	margin: 15px 0 15px 0;
	display: inline-block;
	float: left;

	.compact-menu--visible & {
		visibility: hidden;
	}

}

.page-header-site-name {
	text-indent: -6789px;
	display: block;
}

.page-header-navigation {
	margin-top: 18px;
	float: right;

	/**
	 * Hide navigation for small resolutons.
	 * A javascript navigation will be displayed instead.
	 */
	@include media($smallToMedium) {
		.js & {
			display: none;
		}
	}
}

.page-header-navigation li {
	display: inline-block;
	margin-left: 25px;

	a {
		color: #FFF;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 125%;

		&:hover {
			color: #F1F1F1;
		}
	}

	&.active-trail a, &.active a {
		opacity: .8;
	}
}

.page-body {

	margin-top: 35px;

	@include media($small) {
		@include outer-container(700px);
		@include pad(0 15px 0 15px);
	}

	@include media($medium) {
	  @include outer-container(990px);
	 	@include pad(0 30px 0 30px);
	}

	@include media($large) {
	  @include outer-container(1200px);
	  @include pad(0 40px 0 40px);
	}

	position: relative;


	.node-type-front-subpage &,
	.node-type-news & {

		@include media($large) {
			@include outer-container(900px);
			@include pad(0);
		}
	}

}

.page-body-pre {

}

.page-body--front {

	max-width: 100%;
	min-height: 100%;
	padding: 0;
}

.page-body-title {
	font-size: 170%;
	text-transform: uppercase;
	margin-bottom: 15px;
	margin-top: 0px;
	padding-bottom: 15px;
	border-bottom: 1px solid #000;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;

	/**
	 * Page banner/header image is present
	 */
	body:not([class*=unobtrusive-headline]) .page-banner + .page-body & {

		margin-top: -75px;
		color: #FFF;
		border-bottom: 0px;
		@extend %bold-font;

		@include media($small) {
			font-size: 130%;
			margin-top: -62px;
		}
	}
}

.page-body-title-count {
	color: $highlight-color;
}

.page-body-sidebar-image {

	background-size: 100% auto;

	@include span-columns(3 of 8);
	@include omega();

	@include media($small) {

		@include span-columns(1 of 1);
		@include omega();
	}

	img {
		display: block;
		opacity: .5;
		width: 100%;
		background-color: #FFF;
	}

}

body:not([class*=unobtrusive-headline]) .page-banner {

	width: 100%;
	overflow: hidden;

	img {
		display: block;
		width: 100%;
		height: auto;

		@include media($small) {

			.path-unternehmen-team & {

				width: 160%;
				max-width: 160%;
				margin-left: -30%;
			}
		}
	}


	&:after {
		content: '';
		display: block;
		background: rgba($highlight-color, 0.8);
		width: 100%;
		height: 50px;
		margin-top: -50px;
		position: relative;
		z-index: 0;

		@include media($small) {
			height: 30px;
			margin-top: -30px;
		}
	}

}

.page-banner img {
	display: block;
}

.page-bar {
  background-color: #e6e7e9;
  position: relative;
  @include clearfix;
}

.page-bar-inner {

	@include media($small) {
	  @include outer-container(700px);
	}

	@include media($medium) {
	  @include outer-container(990px);
	 	@include pad(0 30px 0 30px);
	}

	@include media($large) {
	  @include outer-container(1200px);
	  @include pad(0 40px 0 40px);
	}

	position: relative;


	.contact-page & {
		max-width: 100%;
		padding: 0;
	}

}

.page-footer-copyright {
	float: right;
	font-size: 120%;
}

.page-footer {

	background-color: #000;
	position: absolute;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 8px 0;
	color: #FFF;

	@include media($small) {
		font-size: 80%;
	}

	&.page-footer-front {
		position: relative;
	}

	nav {
		display: inline-block;
		font-size: 120%;
	}

	a, a:link, a:active, a:visited {
		color: #FFF;
	}

	li {

		display: inline-block;

		a, a:link, a:active, a:visited {
			color: #FFF;
			padding: 3px 0 3px 0;
			text-transform: uppercase;

			&:after {
				content: ' | ',
			}
		}
	}

	li.last {

		a:after {

			display: none;
		}
	}
}

.page-footer-inner {
	position: relative;
	padding: 0;
	margin: 0 auto 10px auto;
	text-align: left;

	@include media($small) {
		max-width: 90%;
	}

	@include media($medium) {
		max-width: 90%;
	}

	@include media($large) {
		max-width: 900px;
	}


	&:after {
		content: '';
		display: block;
		background: url(../img/page-footer-spotlight.jpg) no-repeat right bottom;
		background-size: contain;
		width: 266px;
		height: 160px;
		position: absolute;
		bottom: -18px;
		left: -30px;
		transform: translateX(-100%);
	}
}
