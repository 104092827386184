
.compact-menu-trigger {
	padding: 20px 35px 16px 0px; 
	display: block;
	position: absolute;
    z-index: 992;
	top: 13px;
	right: 80px;

  @include media($medium) {
    right: 30px;
  }

  @include media($small) {
    right: 15px;
  }
}

.compact-menu-trigger span, .compact-menu-trigger span:before, .compact-menu-trigger span:after {
  cursor: pointer;
  height: 4px;
  width: 35px;
  background-color: #e41513;
  position: absolute;
  display: block;
  content: '';
}

.compact-menu-trigger span {
	background-color: #e41513;
  @include transform(rotate(0));
  @include transition(all 100ms linear);
}


.compact-menu-trigger span:before {
  @include transform(translateY(-10px) rotate(0));
  @include transition(transform 200ms linear);
}


.compact-menu-trigger span:after {
  @include transform(translateY(10px) rotate(0));
  @include transition(transform 200ms linear);
}


.compact-menu-trigger.active span {
  background-color: #FFF;

 .compact-menu--visible & {
  background-color: #000;
 }
}

.compact-menu-trigger.active span:before {
  @include transform(translateY(0px) rotate(45deg));
}

.compact-menu-trigger.active span:after {
  @include transform(translateY(0px) rotate(-45deg));
}

$compact-menu-width: 100%;

.compact-menu {
	z-index: 991;
	width: 100%;
  height: 100%;
  margin: 0;
  top: -9999px;
  position: fixed;
  left: 0;
  background: #000;

  .compact-menu--visible & {
    top: 0;
  }
}

.compact-menu-navigation {
	position: relative;
  top: 0;
  left: 80px;
	z-index: 991;
  width: 100%;
  max-width: 1200px;
  padding: 63px 40px 5px 0px;
	@include transition(all 200ms 100ms linear);	
  opacity: 0;

  @include media($small) {
    left: 20px;
  }

	.compact-menu--visible & {
		opacity: 1;
	}
}

.compact-menu-navigation > ul > li > a {
	background: #000;
	font-size: 550%;
	display: block;
	text-transform: uppercase;
  line-height: 1.2;	
  @extend %bold-font; 
	
  &:link, &:visited {
    color: rgba(255,255,255,1);
  }

	&:active, &:hover {
		color: rgb(228, 21, 19);
	}

  @include media($small) {
    font-size: 300%;
  }

}

.compact-menu-navigation li.expanded ul {
  display: none;
}


.compact-menu-navigation li.expanded ul a {
  color: #FFF;
  font-size: 150%;
  display:block;
  padding-left: 2px;

  &:active, &:hover {
    color: rgb(228, 21, 19);
  }
}



