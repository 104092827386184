.related-products {

}

.related-products-item {

  @include media($small) {
		@include span-columns(6 of 12);
  	@include omega(2n);
  }

	@include media($medium) {
		@include span-columns(4 of 12);
	  @include omega(3n);
	}	

	@include media($large) {
		@include span-columns(3 of 12);
		@include omega(4n);
	}

	margin-bottom: 15px;

	img {
		width: 100%;
	}
}

.related-products-item-title {
	text-transform: uppercase;
}