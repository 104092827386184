
@keyframes marquee {
  0%   { @include transform(translateX(0%)) }
  100% { @include transform(translateX(-105%))}
}

.marquee {
  width: 100%;
  position: relative;
  margin: 50px auto;
  overflow: hidden;
  top: 0px;
  height: 44px;
  color: #eee;
  font-size: 120%;
  @extend %bold-font; 

  @include media($smallToMedium) {
    display: none;
  }

  &:before, &:after {
    content: '';
    position: absolute;
    top: 0;
    width: 20px;
    height: 100%;
    z-index: 3;
  }

  &:before {
    left: 0;
    @include linear-gradient(to right, #000, rgba(0,0,0,0));
    background-color: transparent;
  }

  &:after {
    right: 0;
    @include linear-gradient(to right, rgba(0,0,0,0), #000);
    background-color: transparent;
  }
}

.marquee-text {
  position: absolute;
  display: block;
  white-space: nowrap;
  @include animation(marquee 200s linear infinite);
}

