.login-register {

}

#user-login {

	> div {
		margin-top: 15px;
	}

	label {
		margin-bottom: 3px;
		color: #444;
	}

	small {
		display: none;
	}

	.login-password {
		color: #888;
		@extend %regular-font;	
		margin: 10px 0;
		display: block;
		font-size: 80%;

		&:before {
			content: 'c';
			font-size: 8px;
			display: inline-block;
			margin-right: 1px;
			font-family: "lamp83-icons";
		}

	}

}