
$border-box-sizing: true !default;
$column: 90px;
$gutter: 50px;
$grid-columns: 12;
$max-width: 1120px;

$small: new-breakpoint(min-width 0px max-width 699px 1);
$medium: new-breakpoint(min-width 700px max-width 989px 6);
$large: new-breakpoint(min-width 990px 12);

// TODO: dashed names instead of camel case
$mediumToLarge: new-breakpoint(min-width 700px 6);
$smallToMedium: new-breakpoint(min-width 0px max-width 989px);

