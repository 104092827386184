.newsletter-block {
  @include transition(0.1s all linear);
  @include transform(translateX(260px));

  background: #fff;
  height: 164px;
  padding-left: 40px;
  position: fixed;
  //bottom: 0px;
  top: 360px;
  right: 0;
  z-index: 960;

  width: 300px;
  clear: both;
  //overflow: hidden;
  box-shadow: 0 0 1px rgba(0,0,0,.3);

  @include media($smallToMedium) {
    position: relative;
    width: 100%;
    border: 0px solid #000;
    box-shadow: none;
    padding-top: 20px;
    @include transform(translateY(0px));
  }

  &__title {
    @include transform(rotate(-90deg));
    @include transform-origin(62px 102px);
    @extend %bold-font;

    background: rgba(0,170,205,1);
    color: #FFF;
    cursor: pointer;
    display: block;
    //font-size: 22px;
    height: 40px;
    line-height: 40px;
    padding: 0 0 0 15px;
    position: absolute;
    width: 164px;

    @include media($smallToMedium) {
      background: #FFF;
      color: #000;
      padding: 0 0 5px 0;
      border-bottom: 1px solid #000;
      margin-bottom: 10px;
      margin-top: 10px;
    }
  }

  .button {
    background: linear-gradient(to bottom, #e1e1e1 0%,rgba(0,0,0,0) 100%);
    border: 1px solid #b0b0b0;
    display: inline-block;
    line-height: 1;
    margin: 15px 0 0;
    padding: 6px 10px;
  }
}

.newsletter-block__content {
  //font-size: 20px;
  padding: 15px;

  @include media($smallToMedium) {
    margin-top: 0;
    margin-left: 115px;
  }
}

.newsletter-block--open {
  @include transform(translateY(0px));

  .newsletter-block__bnt {
    @include transform(rotate(0deg));
    //@include transform-origin(13px 17px);
  }
}

.newsletter-block__bnt {
  @include transform(rotate(180deg));
  //@include transform-origin(13px 17px);

  //background: #000;
  color: #FFF;
  display: block;
  height: 18px;
  outline: 0 none;
  position: absolute;
  right: 10px;
  top: 10px;
  width: 22px;

  &:before {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
  }

  @include media($smallToMedium) {
    display: none;
  }

  &:link, &:hover, &:active, &:visited {
    color: #EEE;
    outline: 0 none;
  }
}
