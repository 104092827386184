.news-list {
  overflow: auto;
  margin-top: 3em;
}


.news-list-item {

  @include media($small) {
    @include span-columns(1 of 1);
    @include omega(1n);
  }

  @include media($medium) {
    @include span-columns(3 of 6);
    @include omega(2n);
  }

  @include media($large) {
    @include span-columns(4 of 12);
    @include omega(3n);
  }

  .news-list-related & {
    @include media($small) {
      @include span-columns(1 of 1);
      @include omega(1n);
    }

    @include media($medium) {
      @include span-columns(3 of 6);
      @include omega(2n);
    }

    @include media($large) {
      @include span-columns(4 of 12);
      @include omega(3n);
    }

  }

  margin-bottom: 4.5em;
}

.news-list-item-category {
  text-align: center;
  display: block;
  position: relative;
  z-index: 2;

  &:before {
    z-index: 1;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    border-top: 1px solid #000;
  }
}

.news-list-item-category-title {
  background: #FFF;
  padding: 0 0.5em;
  position: relative;
  z-index: 3;
  font-size: 120%;
  text-transform: uppercase;
}

.news-list-item-title a {
  margin-top: 0.35em;
  font-size: 180%;
  line-height: 1.2em;
  display: block;
  text-align: center;
  color: $brand-red-color;
  background-color: #FFF;
  padding: 0 5px;
  margin-bottom: 0.4em;
  @extend %bold-font;
}

.news-list-item-image {

}

.news-list-item-text {

  text-align: justify;
  font-size: 110%; /** **/
  line-height: 1.5em;

  p {
    margin-bottom: 0;
  }

  a {
    @extend %bold-font;
    color: $highlight-color;
  }
}

.news-list-related .pager {
  margin-top: -2em;
  margin-bottom: 2em;
  text-align: center;

  li{
    display: inline-block;
    a {
      padding: 20px;
    }
  }
  .ellipsis {
    display: none;
  }

}

