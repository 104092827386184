.newsletter-subscription {
  clear: both;

  .title {
    margin-bottom: 15px;
    padding-top: 45px;
    padding-bottom: 15px;
    border-bottom: 1px solid #000;
  }

  input {
    margin: 0 0 1.42em 0;
  }

}