#backtotop {
  width: 40px;
  height: 40px;
  background-size: cover;
  display: none;
  z-index: 999 !important;

  @include media($small) {
    display: block;
  }
}