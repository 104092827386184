.contact-page {

}

.contact-page-form {

	@include span-columns(5 of 8);

	@include media($small) {
		@include span-columns(1 of 1);
		@include omega();		
	}

	input, textarea, select {
		width: 100%;
	}

	.form-item, .form-actions {
		margin-top: 1.42em;
  	margin-bottom: 1.42em;
	}

	.grippie {
		display: none;
	}

	.webform-component--shipping-address {
		border-width: 1px 0px 0px;
		padding: 0px;
		margin: 0;

		legend {
			background: #FFF;
			display: block;
			padding-right: 4px;
		}
	}

	.webform-component--shipping-address--catalog-order-name {
		@include span-columns(6 of 12);
		@include media($small) {
			@include span-columns(1 of 1);
		}
	}

	.webform-component--shipping-address--catalog-order-street {
		@include span-columns(6 of 12);
		@include omega();
		@include media($small) {
			@include span-columns(1 of 1);
		}
	}

	.webform-component--shipping-address--catalog-order-zip-code {
		@include span-columns(4 of 12);
		@include media($small) {
			@include span-columns(1 of 1);
		}
	}

	.webform-component--shipping-address--catalog-order-city {
		@include span-columns(4 of 12);	
		@include media($small) {
			@include span-columns(1 of 1);
		}	
	}

	.webform-component--shipping-address--catalog-order-country {
		@include span-columns(4 of 12);
		@include omega();	
		@include media($small) {
			@include span-columns(1 of 1);
		}
	}
}

.contact-page-info {

	@include span-columns(3 of 8);
	@include omega();

	@include media($small) {
		@include span-columns(1 of 1);
	}

	position: relative;
	padding-top: 35px;
}


.contact-page-map {

	height: 300px;
	background: url('../img/gmaps.png');
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;

	h3 {
		cursor: pointer;
	}

	@include media(min-height 750px) {
		height: 350px;
	}

  @include media(min-height 800px) {
     height: 400px;
  }

  @include media(min-height 1000px) {
     height: 450px;
  }

}

.contact-page-xing {
  position: absolute;
  right: 0px;
  margin-top: -75px;
  width: 50px;
  height: 50px;
}

.leaflet-container a {
	color: #AAA !important;
}
