.menu-references-item {

	margin-bottom: 20px;

	@include media($large) {
		@include span-columns(6 of 12);
  		@include omega(2n);
	}

	@include media($medium) {
		@include span-columns(6 of 12);
  		@include omega(2n);
	}

	@include media($small) {
		@include span-columns(12 of 12);
  		@include omega(1n);
		margin-bottom: 10px;
	}
}

.menu-references-item-link {
	display: block;
	padding-bottom: 66%;
	position: relative;
	width: 100%;
	overflow: hidden;
}

.menu-references-item-title {
	position: absolute;
	top: 0;
	left: 0;
	background-color: rgba($highlight-color, 0.8);
	width: 100%;
	color: #FFF;
	text-transform: uppercase;
	font-size: 170%;
	padding: 4px 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

	@include media($small) {
		font-size: 120%;
	}
}

.menu-references-item-title--mini {
  font-size: 100%;
  padding: 2px 6px;
}

.menu-references-item-image,
.menu-references-item-image-hover {
	position: absolute;
	top: 0;
	left: 0;
	padding-bottom: 66%;
	width: 100%;
	max-width: 100%;
}

.menu-references-item-image-hover {
	@include transition(opacity 0.5s ease-out);
	opacity: 0;

	a:hover & {
		opacity: 1;
	}
}