@charset "UTF-8";

@font-face {
  font-family: "lamp83-icons";
  src:url("../fonts/lamp83-icons.eot");
  src:url("../fonts/lamp83-icons.eot?#iefix") format("embedded-opentype"),
    url("../fonts/lamp83-icons.woff") format("woff"),
    url("../fonts/lamp83-icons.ttf") format("truetype"),
    url("../fonts/lamp83-icons.svg#lamp83-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}

/**
 * CUSTOMIZED TO CREATE ABSTRACT BASE ICON CLASS !!!!
 */
[data-icon]:before {
  font-family: "lamp83-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

%icon-l83:before {
  font-family: "lamp83-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-l83-"],
[class*=" icon-l83-"]{
  @extend %icon-l83;
}

.icon-l83-chevron-down:before {
  content: "a";
}
.icon-l83-chevron-left:before {
  content: "b";
}
.icon-l83-chevron-right:before {
  content: "c";
}
.icon-l83-chevron-up:before {
  content: "d";
}
.icon-l83-ios-search-strong:before {
  content: "f";
}
.icon-l83-ios-arrow-down:before {
  content: "g";
}
.icon-l83-ios-arrow-up:before {
  content: "h";
}
.icon-l83-arrow-up-b:before {
  content: "i";
}
.icon-l83-android-close:before {
  content: "e";
}
.icon-l83-ios-arrow-left:before {
  content: "j";
}
.icon-l83-ios-arrow-back:before {
  content: "k";
}
.icon-l83-document-text:before {
  content: "l";
}
.icon-l83-ios-arrow-right:before {
  content: "m";
}

