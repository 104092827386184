.node-type-reference {

	margin-top: 25px;

	.page-banner img {
		width: 100%;
		height: auto;
	}
}

.node-type-reference .group-left {
	margin-bottom: 100px;

	@include media($small) {
		width: 100%;
		@include clearfix();
	}

	@include media($medium) {
		width: 50%;
	}

	@include media($large) {
		width: 50%;
	}
}

.node-type-reference .group-right {

	@include media($small) {
		@include clearfix();
		width: 100%;
	}

	@include media($medium) {
		width: 48%;
		margin-left: 2%;
	}

	@include media($large) {
		width: 48%;
		margin-left: 2%;
	}

	position: relative;
	margin-bottom: 100px;
}

.node-type-reference .group-left {

	> [class*=field] {
		line-height: 1.3;
	}

	.field-label {
		clear: both;
		width: 100px;
		float: left;
	}

	.inline {
		margin-left: 100px;
	}
}

.node-type-reference .group-header {

	margin-bottom: 30px;

	.field-label {
		@extend %bold-font;

		&:before {
			content: '/';
			display: inline-block;
			@extend %regular-font;
		}

		&:nth-child(2):before {
			content: '';
			display: none;
		}
	}


	> [class*=field-ref] {
		display: inline;
	}
}

.node-type-reference .group-right .field-label,
.node-type-reference .group-right .block-title,
.node-type-reference .group-left .block-title,
.node-type-reference .group-left h3.field-label,
.node-type-reference .field-info h3,
.node-type-reference .field-body label{
	display: block;
	width: 100%;
	margin-bottom: 15px;
	margin-top: 30px;
	color: $brand-red-color;
	font-size: 16px;
	text-transform: uppercase;
	line-height: 1.3;
}

.node-type-reference .field-body {

	margin-top: 15px;
	clear: both;
}

.node-type-reference .field-ref-images {
	margin-bottom: 10px;
	display: none;
	position: relative;

	&:nth-child(1),
	&:nth-child(2),
	&:nth-child(3),
	&:nth-child(4),
	&:nth-child(5),
	&:nth-child(6) {
		display: block;
	}


	a {
		outline: 0;
	}
}


.node-type-reference .field-referenced-products {
	@include outer-container();
}


.node-type-reference .field-referenced-products-image {

	@include media($small) {
		@include span-columns(6 of 12);
		@include omega(2n);
	}

	@include media($medium) {
		@include span-columns(6 of 12);
		@include omega(2n);
	}

	@include media($large) {
		@include span-columns(6 of 12);
		@include omega(2n);
	}

	margin-bottom: 30px;

}

.node-type-reference .field-slideshow-controls {
	position: absolute;
	top: 50%;
	z-index: 20;
	transform: translateY(-50px);
	width: 100%;
}

.node-type-reference .field-slideshow-controls-prev,
.node-type-reference .field-slideshow-controls-next {
	position: absolute;
	font-size: 400%;
	color: rgba(255,255,255,.8);
	text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);

	&:hover {
		color: #FFF;
	}
}

.node-type-reference .field-slideshow-controls-prev {
	left: 0px;
	top: 0;
}

.node-type-reference .field-slideshow-controls-next {
	right: 0px;
	top: 0;
	transform: scaleX(-1);;
}


.node-type-reference .field-more-references {

	margin-top: 40px;
	position: relative;
	text-align: center;

	&:before {
		z-index: 1;
		content: '';
		display: block;
		height: 1px;
		width: 100%;
		position: absolute;
		top: 50%;
		left: 0;
		border-top: 1px solid $highlight-color;
	}

	.title {
		display: inline;
		padding: 0 0.5em;
		z-index: 3;
		font-size: 16px;
		text-transform: uppercase;
		color: $highlight-color;
		background: #FFF;
		position: relative;
	}

	.view-reference-category {
		text-align: left;
	}
}