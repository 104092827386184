
.product-groups {
	margin-top: -25px;

	@include media($small) {
		margin-top: -10px;
	}
}


.product-groups-item {

	@include transition(border 0.4s ease-in-out);
	min-height: 150px;
	margin-top: 25px;
	cursor: pointer;
	border-bottom: 6px solid #FFF;

  @include media($small) {
		@include span-columns(6 of 12);
		@include omega(2n);
		margin-top: 10px;
  }

	@include media($medium) {
		@include span-columns(3 of 6);
		@include omega(2n);
	}	

	@include media($large) {
		@include span-columns(3 of 12);
		@include omega(4n);
		//max-width: 260px;
	}
}

.product-groups-item-title {
	margin-top: 10px;
	border-bottom: 6px solid #FFF;
	font-size: 120%;
	transition: border-color 0.3s linear;

	.product-groups-item-multi .product-groups-item-anchor:target ~ .product-groups-item-link & {
		border-color: $highlight-color;
	}

}

.product-groups-item-link {
	outline: 0;
}

.product-groups-item-image {
	width: 100%;
	height: 0px;
	padding-bottom: 100%;
}

.product-groups-item-anchor {
	position: fixed;
	top: 0;
	left: 0;
	display: block;
}

.product-groups-item-image img {
	display: block;
	width: 100%;

	@include media($small) {
		width: auto;
	}
}

.product-groups-item-icons {
	margin-top: -29px;
	font-size: 0px;
}


.product-groups-item-icons .product-icon {
	margin-right: 4px;
}

.product-groups-item--selected {
	border-bottom: 6px solid #86b7b1;
}

